import React from "react";

function Faq() {
  return (
    <div className="container">
      <div className="row py-5">
        <h1 className="py-3 txt">Häufig gestellte Fragen!</h1>
        <div
          className="accordion accordion-flush greybg p-2"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Wer wird meine Arbeit schreiben?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Wir haben ein Team von Expertenautoren mit fachspezifischem
                Wissen und Erfahrung im akademischen Schreiben. Der dem Projekt
                zugewiesene Autor wird über einen Hintergrund in Ihrem
                Studienbereich und die notwendigen Fähigkeiten verfügen, um
                qualitativ hochwertige Arbeit zu leisten.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Wird mein Aufsatz originell sein?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Ja, alle unsere Arbeiten sind garantiert zu 100 % original und
                frei von Plagiaten. Wir verwenden fortschrittliche
                Plagiatsprüfungswerkzeuge und -techniken, um sicherzustellen,
                dass Ihre Arbeit einzigartig und originell ist.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Wie viel wird es kosten, meine Arbeit zu schreiben?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Die Kosten für Ihre Arbeit hängen von einer Reihe von Faktoren
                ab, einschließlich der Art der Arbeit, der Länge und der Frist.
                Wir bieten flexible Preisoptionen, die zu Ihrem Budget und Ihren
                Bedürfnissen passen.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingfour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefour"
                aria-expanded="false"
                aria-controls="flush-collapsefour"
              >
                Was ist, wenn ich mit dem Papier nicht zufrieden bin?
              </button>
            </h2>
            <div
              id="flush-collapsefour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Wir bemühen uns, hochwertige Arbeit zu liefern, die Ihren
                Anforderungen entspricht und Ihre Erwartungen übertrifft. Falls
                Sie aus irgendeinem Grund mit dem Papier nicht zufrieden sind,
                bieten wir kostenlose Überarbeitungen an, bis Sie vollständig
                zufrieden sind.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingfive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefive"
                aria-expanded="false"
                aria-controls="flush-collapsefive"
              >
                Wie gebe ich eine Bestellung auf?
              </button>
            </h2>
            <div
              id="flush-collapsefive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Eine Bestellung aufzugeben ist einfach. Füllen Sie einfach das
                Bestellformular auf unserer Website aus und geben Sie alle
                notwendigen Details zu Ihrem Projekt an. Sie können auch unser
                Kundenserviceteam kontaktieren, um Unterstützung bei der Aufgabe
                Ihrer Bestellung zu erhalten.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingsix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsesix"
                aria-expanded="false"
                aria-controls="flush-collapsesix"
              >
                Was ist, wenn ich meine Arbeit dringend brauche?
              </button>
            </h2>
            <div
              id="flush-collapsesix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingsix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Wir verstehen, dass Fristen eng sein können, weshalb wir
                flexible Lieferoptionen, einschließlich Eilaufträgen, anbieten.
                Kontaktieren Sie uns, um Ihre Bedürfnisse zu besprechen, und wir
                werden unser Bestes tun, um Ihren Zeitrahmen einzuhalten.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Faq;
