import BlueDash from "../blue-dash";
import Heading from "../heading";
import {Link} from "react-router-dom";
// import TemplateCarousel from "./template-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import template1 from "./../../images/template1.jpg";
import template2 from "./../../images/template2.jpg";
import template3 from "./../../images/template3.jpg";
const TemplatesSection = () => {
  return (
    <div className="">
      <div className="container">
        <div className="py-5 row ">
          <div className="pt-4 col-lg-6 col-md-12 col-sm-12">
            <h1 className="head1">
              Schöne, sofort einsatzbereite Lebenslauf-Vorlagen
            </h1>
            <BlueDash />
            <p>
              Überzeugen Sie Arbeitgeber und Personalvermittler, indem Sie eine
              unserer über 25 eleganten, professionell gestalteten
              Lebenslaufvorlagen verwenden. Herunterladen als Word- oder
              PDF-Dokument.
            </p>
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Vorlage auswählen
              </Link>
            </button>
          </div>
          <div className="pt-3 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
            <Carousel infiniteloop>
              <div>
                <img src={template1} alt="" />
              </div>
              <div>
                <img src={template2} alt="" />
              </div>
              <div>
                <img src={template3} alt="" />
              </div>
            </Carousel>

            {/* <TemplateCarousel /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatesSection;
