import React from "react";
import "./Cvex.css";
import { Link } from "react-router-dom";
function Cvex() {
  return (
    <div className="backfooter">
      <div className="container ">
        <div className="py-5 row txt d-flex justify-content-center">
          <div className="pt-4 col-lg-8 col-md-12 col-sm-12">
            <h1>
              Benutzerfreundlich. Professionell. Effizient. Probieren Sie noch
              heute unseren Bewerbungsschreiben-Generator aus!
            </h1>
            <p>
              Wie lange dauert es, ein Bewerbungsschreiben zu schreiben?
              Stunden? Tage? Mit unserem Bewerbungsschreiben-Generator sind Sie
              in wenigen Minuten fertig. Erstellen Sie in mehreren Klicks ein
              überzeugendes und effektives Bewerbungsschreiben. Verwenden Sie
              unsere vorgefertigten Phrasen, wählen Sie ein Design aus, fügen
              Sie Ihre Details und Ideen ein. Schnell und einfach.
            </p>
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Probier es jetzt aus.
              </Link>
            </button>
            &nbsp; &nbsp; &nbsp;
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Beispiele für Bewerbungsschreiben
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cvex;
