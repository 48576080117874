import { AiFillStar } from "react-icons/ai";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import Review from "./review";

const TestimonialsSection = () => {
  const reviews = [
    {
      heading: "",
      rating: 5,
      user: "Jeffery Jones",
      review:
        "Ich habe in der Vergangenheit mehrere Lebenslauf-Builder verwendet, aber diese Website ist bei weitem die beste.  Die Anpassungsoptionen sind endlos, sodass ich meinen Lebenslauf an jede Bewerbung anpassen kann.  Der Prozess des Lebenslauf-Erstellens ist wirklich einfach und es fühlt sich nicht so an, als würde ich einen Lebenslauf erstellen!",
    },
    {
      heading: "",
      rating: 5,
      user: "Victor Smith",
      review:
        "Ich war zunächst zögerlich, einen Lebenslauf-Builder zu verwenden, aber diese Website hat meine Erwartungen übertroffen und die Arbeit mit ihrer interaktiven Benutzeroberfläche so einfach gemacht. Die Vorlagen sind visuell ansprechend und präsentieren meine Fähigkeiten und Erfahrungen in bestmöglichem Licht. Ich bin so froh, dass ich diese Website gefunden habe!",
    },
    {
      heading: "",
      rating: 5,
      user: "Rachel Ashley",
      review:
        "Ich kann diesen Lebenslauf-Builder nicht genug empfehlen.  Die Website ist einfach zu navigieren und die Vorlagen sind anpassbar und professionell. Das beste Merkmal, das ich fand, war die 'ATS-Kompatibilität', die nicht nur einen optimierten Lebenslauf erstellte, sondern mir auch half, meinen Traumjob zu bekommen.  Diese Website ist ein Wendepunkt für alle, die ihre Jobsuche auf ein neues Level heben möchten.",
    },
  ];
  const avgRating = 4;
  return (
    <div>
      <div className="flex justify-between gap-8 flex-col lg:flex-row">
        <div className="flex flex-col gap-2 lg:gap-6 items-center lg:items-start">
          <div className="flex flex-col gap-1 items-center lg:items-start">
            <p className="text-xl font-medium">{`${avgRating} out of 5`}</p>
            <div className="flex gap-0">
              {new Array(avgRating).fill("").map((_, i) => (
                <AiFillStar key={i} className={"text-blue-500 h-8 w-8"} />
              ))}
              {!!(5 - avgRating) &&
                new Array(5 - avgRating)
                  .fill("")
                  .map((_, i) => (
                    <AiFillStar className="text-slate-400 h-8 w-8" key={i} />
                  ))}
            </div>
          </div>
          <p className="text-xs text-slate-400">Based on 44,112 reviews</p>
        </div>
        <div className="flex flex-col gap-7 overflow-hidden">
          <div className="overflow-hidden pb-4 scrollbar scrollbar-h-1 scrollbar-rounded-full scrollbar-track-slate-300 scrollbar-corner-rounded-full  scrollbar-thumb-blue-500">
            <div className="flex gap-4">
              {reviews.map(({rating, user, review, heading}, i) => (
                <Review key={i} rating={rating} user={user} review={review} heading={heading} />
              ))}
            </div>
          </div>
          {/* <div className="h-[2px] w-full bg-slate-400 rounded-full"></div> */}
          {/* <div className="flex gap-4 items-center">
            <div className="flex gap-2">
              <div className="grid place-items-center w-10 h-10 bg-slate-200 rounded-full">
                <MdArrowBackIos className="ml-2 h-5 w-5 text-slate-500" />
              </div>
              <div className="grid place-items-center w-10 h-10 bg-slate-200 rounded-full">
                <MdArrowForwardIos className="ml-0.5 h-5 w-5 text-slate-500" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
