import React, { useEffect } from "react";
import "./Feature.css";

import builder from "../../images/features/bulder.jpeg";
import cover from "../../images/features/cover.png";
import datasave from "../../images/features/datasave.jpg";
import interview from "../../images/features/interview.png";
import job from "../../images/features/job.png";
import resume from "../../images/features/resume.png";
import spell from "../../images/features/spell.png";
import summary from "../../images/features/sumary.png";
import template from "../../images/features/template.png";
import AOS from "aos";
import "aos/dist/aos.css";
function Feature() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="feature" id="features">
      <div className="container">
        <div className="py-5 row">
          <h3 className="py-5 txt">
            Funktionen, die darauf ausgelegt sind, Ihnen zu Ihrem Traumjob zu
            verhelfen.
          </h3>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12 "
              data-aos="zoom-in-down"
            >
              <img src={builder} alt="f1" className="center vert-move" />
              <h5>Einfacher Online-Lebenslauf-Builder</h5>
              <p>
                Erstellen Sie in wenigen Minuten einen beeindruckenden
                Lebenslauf, ohne Ihren Webbrowser zu verlassen.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={spell} alt="f1" className="center vert-move" />
              <h5>Automatische Rechtschreibprüfung</h5>
              <p>
                Unser integrierter Rechtschreibprüfer kümmert sich um die
                Grammatik für Sie. Erstellen Sie einen Lebenslauf ohne
                Tippfehler oder Fehler.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={datasave} alt="f1" className="center vert-move" />
              <h5>Ihre Daten sind sicher.</h5>
              <p>
                Ihre Daten werden privat gehalten und durch starke
                256-Bit-Verschlüsselung geschützt.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={summary} alt="f1" className="center vert-move" />
              <h5>Automatischer Zusammenfassungs-Generator</h5>
              <p>
                Erstellen Sie mit nur einem Klick ein beeindruckendes
                Lebenslaufprofil oder ein Bewerbungsschreiben. Die
                Schreibblockade ist kein Hindernis mehr. Kostenlos ausprobieren!
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={template} alt="f1" className="center vert-move" />
              <h5>Genehmigte Vorlagen</h5>
              <p>
                Professionell gestaltete Lebenslaufvorlagen und Beispiele
                (+Anleitungen). Einfach bearbeiten und in 5 Minuten
                herunterladen.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={job} alt="f1" className="center vert-move" />
              <h5>Jobverfolgung</h5>
              <p>
                Wir halten Sie der Konkurrenz voraus, indem wir die Arbeitgeber
                und Stellen verfolgen, bei denen Sie sich bewerben.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={interview} alt="f1" className="center vert-move" />
              <h5>Interview Schule</h5>
              <p>
                Unser einzigartiges digitales Werkzeug wird Ihnen helfen,
                Aufträge zu gewinnen! Erhalten Sie Feedback, Tipps und
                verbessern Sie Ihre Interviewfähigkeiten.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={resume} alt="f1" className="center vert-move" />
              <h5>Mehrere Lebenslauf-Formate</h5>
              <p>
                Speichern Sie Ihren perfekten Lebenslauf in jedem gängigen
                Format, einschließlich Microsoft Word und PDF, mit nur einem
                Klick.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={cover} alt="f1" className="center vert-move" />
              <h5>Anschreiben</h5>
              <p>
                Unser Bewerbungsschreiben-Generator funktioniert mit derselben
                Leichtigkeit und der Verwendung eleganter Vorlagen wie der
                Lebenslauf-Generator.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Feature;
