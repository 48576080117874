import React, { useState } from "react";
import Button from "../../../InputControl/Button";
import InputControl from "../../../InputControl/InputControl";

export default function CertificationBlock(props) {
  const [check, setCheck] = useState(true);
  const [certificationName, setCertificationName] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [description, setDescription] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const handleSubmit = () => {
    if (
      [
        certificationName,
        organization,
        description,
        startDate,
        endDate,
      ].includes(false) ||
      new Date(startDate) > new Date(endDate)
    ) {
      setCheck(false);
      props.save(false);
    } else {
      setCheck(true);
      props.save(true);
      props.set([
        ...props.data,
        { certificationName, organization, description, startDate, endDate },
      ]);
    }
  };

  const closeModal = () => {
    setCheck(true);
  };

  return (
    <>
      <div className="flex md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[40%] h-[80px]">
          <InputControl
            field="Certification Name"
            errmsg="Ungültiger Zertifizierungsname"
            isComp
            placeholder="Zertifizierungsname"
            set={setCertificationName}
          />
        </div>
        <div className="w-[100%] md:w-[40%] h-[80px]">
          <InputControl
            field="Awarding Organization"
            errmsg="Ungültige Organisation"
            isComp
            placeholder="Zertifizierung verliehen von"
            set={setOrganization}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[40%] h-[80px]">
          <InputControl
            type="date"
            isComp
            field="Start Date"
            errmsg="Invalid date"
            placeholder="dd-mm-yyyy"
            set={setStartDate}
          />
        </div>
        <div className="w-[100%] md:w-[40%] h-[80px]">
          <InputControl
            type="date"
            isComp
            field="End Date"
            errmsg="Invalid date"
            placeholder="dd-mm-yyyy"
            set={setEndDate}
          />
        </div>
      </div>
      <div className="">
        <div className="w-[100%] md:w-[100%] h-[80px]">
          <InputControl
            field="Description"
            errmsg="Ungültige Beschreibung"
            isComp
            placeholder="Zertifizierungsbeschreibung"
            set={setDescription}
          />
        </div>
      </div>
      <div className="w-full">
        <div className="w-[100px]" onClick={handleSubmit}>
          <Button text="Speichern" />
        </div>
        <div className={`text-red-500 ${!check ? "block" : "hidden"}`}>
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="p-8 bg-white rounded-md">
              <div className="mb-4 text-2xl font-bold">Es gab einen Fehler</div>
              <div className="text-center text-md">
                Bitte stellen Sie sicher, dass alle Details korrekt ausgefüllt
                sind. Stellen Sie sicher, dass die Daten in der richtigen
                Reihenfolge sind.
              </div>
              <div className="flex justify-center mt-4" onClick={closeModal}>
                <Button text="Schließene" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
