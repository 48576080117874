import { TypeAnimation } from "react-type-animation";

import finance from "./../images/finance.png";
import ser from "./../images/image-removebg-preview (1).png";
import BlueDash from "./blue-dash";

const AboutUsSection = () => {
  return (
    <div id="about">
      <div className="flex pt-16 lg:pt-44 pb-10 gap-16 items-center">
        <div className="flex flex-col">
          <p className="text-sm lg:text-2xl  font-semibold mb-2 text-blue-600">
            ÜBER UNS
          </p>
          <p className=" text-2xl lg:text-4xl font-medium ">
            Bei ResumeByte sind wir leidenschaftlich daran interessiert,
            <br />{" "}
            <TypeAnimation
              sequence={["Helfen", 1000, "Karriere", 2000]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              className={
                "italic text-2xl lg:text-4xl text-blue-500 font-serif font-bold"
              }
            />
          </p>
          <BlueDash />
          <p>
            Unsere hochmoderne Technologie optimiert Ihren Lebenslauf für ATS,
            unsere erfahrenen Autoren wissen, was Arbeitgeber wollen, und unser
            personalisierter Service passt unser Angebot an Ihre Karriereziele
            an, um Ihren Erfolg zu steigern.
          </p>
        </div>
        <img
          src={ser}
          alt="finance"
          className=" serpic bounce absolute right-0 opacity-25  w-1/2 sm:w-64 lg:opacity-100 lg:w-72 lg:relative xl:w-1/3"
        />
      </div>
    </div>
  );
};

export default AboutUsSection;
