import React, { useEffect } from "react";
import "./createcover.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import imge from "../../images/Checklist.png";
function Createcover() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="">
      <div className="container ">
        <div className="py-5 row">
          <div
            className="pt-4 col-lg-6 col-md-12 col-sm-12"
            data-aos="zoom-in-right"
          >
            <p>SICHERN SIE SICH IHREN TRAUMJOB</p>
            <h2>
              Erstellen Sie in nur wenigen Minuten eine professionelle
              Geschichte. Verwenden Sie unseren Bewerbungsschreiben-Generator.
            </h2>
            <p>
              Unser Bewerbungsschreiben-Generator ermöglicht es Ihnen, in
              Minuten anstelle von Stunden erstaunliche professionelle
              Bewerbungsschreiben zu erstellen. Kein Schreibblock mehr, kein
              Suchen nach überzeugenden Formulierungen, kein Grübeln über das
              Format. Sei überzeugend ohne Mühe!
            </p>

            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Erstellen Sie ein Bewerbungsschreiben
              </Link>
            </button>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center"
            data-aos="zoom-in-left"
          >
            <img
              src={imge}
              alt="banner"
              height="50% !important"
              className="imge pt-lg-0 pt-md-5 pt-sm-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Createcover;
