import React, { useEffect } from "react";
import "./Guide.css";
import { Link } from "react-router-dom";
import imge from "../../images/guide.png";
import easy from "../../images/guide/easy.png";
import win from "../../images/guide/winner.png";
import tested from "../../images/guide/tested.png";
import AOS from "aos";
import "aos/dist/aos.css";
function Guide() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="backfooter">
      <div className="container ">
        <div className="py-5 row">
          <div
            className="px-3 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center"
            data-aos="fade-right"
          >
            <img
              src={imge}
              alt="banner"
              height="50% !important"
              className="imge pt-lg-0 pt-md-5 pt-sm-5"
            />
          </div>
          <div
            className="pt-4 col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-left"
          >
            {/* <p>SECURE YOUR DREAM JOB</p> */}
            <h2 className="head1 ">
              Verwenden Sie den besten Lebenslauf-Generator als Ihren Leitfaden.
            </h2>
            <p>
              Den Traumjob zu bekommen, kann wie eine unmögliche Aufgabe
              erscheinen. Wir sind hier, um das zu ändern. Verschaffen Sie sich
              einen echten Vorteil mit dem besten Online-Lebenslauf-Generator:
              von Experten erstellt, durch Daten verbessert, von Millionen von
              Fachleuten vertraut.
            </p>
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Erstellen Sie ein Bewerbungsschreiben.
              </Link>
            </button>
            &nbsp; &nbsp;
            <button className="p-3 text-white bluebg">Video ansehen</button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 " data-aos="flip-up">
            <img src={win} alt="f1" className="center1 vert-move" width="20%" />
            <h5 className="pdes">
              Erstellen Sie einen Lebenslauf, der Interviews gewinnt.
            </h5>
            <p>
              Verwenden Sie unseren Lebenslauf-Generator mit seinen
              fortschrittlichen Erstellungstools, um eine professionelle
              Geschichte zu erzählen, die Recruiter, HR-Manager und sogar CEOs
              anspricht.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12" data-aos="flip-up">
            <img
              src={easy}
              alt="f1"
              className="center1 vert-move"
              width="20%"
            />
            <h5 className="pdes">Lebenslauf schreiben leicht gemacht</h5>
            <p>
              Ein Lebenslauf zu schreiben, war noch nie so mühelos.
              Vorgenerierter Text, visuelle Designs und mehr - alles bereits in
              den Lebenslauf-Generator integriert. Geben Sie einfach Ihre Daten
              ein.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12" data-aos="flip-up">
            <img
              src={tested}
              alt="f1"
              className="center1 vert-move"
              width="20%"
            />
            <h5 className="pdes">
              Ein von Personalvermittlern getestetes Lebenslauf-Tool
            </h5>
            <p>
              Unser Lebenslauf-Builder und dessen vorgefertigte Inhalte werden
              von Personalvermittlern und IT-Experten getestet. Wir helfen
              Ihnen, Ihren Lebenslauf im Einstellungsprozess wirklich
              wettbewerbsfähig zu machen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Guide;
