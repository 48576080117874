import Heading from "../heading";

const ProcessHeading = () => {
  return (
    <div className="text-center relative py-24">
      <Heading
        text={"PROZESS"}
        shadowText="before:content-['Prozess']"
        // shadowText="Process"
        size=" before:text-6xl lg:before:text-8xl"
        margin="mb-[2.7rem]"
      />
      <p className="text-2xl lg:text-4xl font-semibold">
        Gestalten Sie Ihren erfolgreichen Lebenslauf in nur wenigen Schritten!
      </p>
      <div className="h-1 w-11  my-7 mx-auto  bg-blue-600 "></div>
      <p className="mt-10 text-2xl">
        Unser Lebenslauf-Builder hilft Ihnen, einen professionellen Lebenslauf
        zu erstellen, um Ihren Traumjob zu bekommen.
      </p>
    </div>
  );
};

export default ProcessHeading;
