import React, { useState } from "react";
import Button from "../../InputControl/Button";
import InputControl from "../../InputControl/InputControl";

export default function PersonalDetails(props) {
  const [check, setCheck] = useState(true);
  const [email, setEMail] = useState(false);
  const [name, setName] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [github, setGithub] = useState(true);
  const [linkedin, setLinkedin] = useState(true);
  const [skills, setSkills] = useState(false);
  const [role, setRole] = useState(false);

  const handleSubmit = () => {
    if ([name, email, mobile, github, linkedin, skills, role].includes(false)) {
      setCheck(false);
    } else {
      setCheck(true);
      props.setData({ name, email, mobile, github, linkedin, skills, role });
      props.setcomp(2);
    }
  };

  // console.log(role)
  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex items-center justify-center text-[30px] md:mb-4">
        Persönliche Angaben
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Name"
            errmsg="Ungültiger Name"
            isComp
            placeholder="Dein Name"
            set={setName}
          />
        </div>
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Role"
            errmsg="Ungültige Rolle"
            isComp
            placeholder="Rolle/Bezeichnung"
            set={setRole}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Email"
            errmsg="Ungültige E-Mail"
            isComp
            placeholder="Geben Sie Ihre E-Mail-Adresse ein"
            set={setEMail}
          />
        </div>
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Mobile"
            errmsg="Ungültige Nummer"
            isComp
            placeholder="Geben Sie die Kontaktnummer ein."
            set={setMobile}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Github"
            errmsg="Ungültiger Link"
            placeholder="Geben Sie Ihren GitHub-Link ein."
            set={setGithub}
          />
        </div>
        <div className="w-[100%] md:w-[47%] h-[80px]">
          <InputControl
            field="Linkedin"
            errmsg="Ungültiger Link"
            placeholder="Geben Sie Ihren LinkedIn-Link ein."
            set={setLinkedin}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-full h-[80px]">
          <InputControl
            field="Skills"
            isComp
            placeholder="Geben Sie Ihre Fähigkeiten ein"
            set={setSkills}
          />
        </div>
      </div>

      <div className="w-full">
        <div className="w-[100px]" onClick={handleSubmit}>
          <Button text="Nächster" />
        </div>
        <div
          className={`text-red-500 ${!check ? "block" : "hidden"} text-[12px]`}
        >
          Etwas stimmt nicht.
        </div>
      </div>
    </div>
  );
}
