import { useEffect } from "react";
import optimise from "../../images/image-removebg-preview (2).png";
import ats from "../../images/ats.png";
import custom from "../../images/custom.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Service = () => {
  // const [hovered, setHovered] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    // <div
    //   onMouseOver={() => setHovered(true)}
    //   onMouseOut={() => setHovered(false)}
    //   className={`w-11/11 p-5 sm:h-[3.7rem] sm:hover:h-48 lg:w-[19rem]  lg:p-9 xl:w-[22rem] lg:h-[11rem] lg:hover:h-[25.5rem] ${color} text-black hover:z-10  transition-all overflow-hidden bord justify-content-center`}
    // >
    //   <div className="flex flex-col gap-2 align-middle transition-all justify-content-center ">
    //     <img
    //       src={image}
    //       alt="images"
    //       width="30%"
    //       className="txt justify-content-center"
    //     />
    //     <p className="text-xl font-semibold whitespace-nowrap txt">{title}</p>
    //     <p
    //       className={`text-ellipsis  ${
    //         hovered ? "line-clamp-none" : "lg:line-clamp-3"
    //       } transition-all overflow-hidden p-4`}
    //     >
    //       {info}
    //     </p>
    //   </div>
    // </div>
    // <section class="text-gray-600 body-font">
    <div class="container px-2 py-5  mx-auto text-gray-600 body-font">
      <div class="flex flex-wrap -m-4 py-5 justify-content-center">
        <div class="xl:w-1/3 md:w-1/2 p-4" data-aos="fade-right">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={optimise}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
              Lebenslauf-Optimierung
            </h3>
            {/* <h2 class="text-lg text-gray-900 font-medium title-font mb-4">San Francisco</h2> */}
            <p class="leading-relaxed text-base">
              Unsere hochmoderne Technologie analysiert Ihren Lebenslauf, um
              sicherzustellen, dass er für ATS optimiert ist. Wir verwenden
              fortschrittliche Algorithmen, um Schlüsselwörter und Phrasen zu
              identifizieren, die für Ihre Zielposition am relevantesten sind,
              und stellen sicher, dass Ihr Lebenslauf so strukturiert ist, dass
              er von ATS leicht lesbar ist.
            </p>
          </div>
        </div>
        <div class="xl:w-1/3 md:w-1/2 p-4" data-aos="fade-up">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={ats}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
              ATS-Kompatibilität
            </h3>
            {/* <h2 class="text-lg text-gray-900 font-medium title-font mb-4">San Francisco</h2> */}
            <p class="leading-relaxed text-base">
              Wir stellen sicher, dass Ihr Lebenslauf mit allen gängigen
              ATS-Systemen der Arbeitgeber kompatibel ist. Das bedeutet, dass
              Ihr Lebenslauf von Recruitern und Personalverantwortlichen leicht
              durchsucht und gefunden werden kann, was Ihre Chancen erhöht,
              bemerkt zu werden.
            </p>
          </div>
        </div>
        <div class="xl:w-1/3 md:w-1/2 p-4 " data-aos="fade-left">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={custom}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">
              Anpassung
            </h3>
            {/* <h2 class="text-lg text-gray-900 font-medium title-font mb-4">San Francisco</h2> */}
            <p class="leading-relaxed text-base">
              Wir bieten personalisierte Dienstleistungen an, um Ihren
              Lebenslauf auf die spezifische Stelle, für die Sie sich bewerben,
              zuzuschneiden. Unser Team von erfahrenen Lebenslaufautoren wird
              mit Ihnen zusammenarbeiten, um Ihre Stärken und Erfolge auf eine
              Weise hervorzuheben, die bei potenziellen Arbeitgebern Anklang
              findet.
            </p>
          </div>
        </div>
      </div>
    </div>

    // </section>
  );
};

export default Service;
