import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ResumeEx.css";
import imge from "../../images/template4.png";
import AOS from "aos";
import "aos/dist/aos.css";
function ResumeEx() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="backfooter">
      <div className="container ">
        <div className="py-5 row">
          <div
            className="pt-4 col-lg-6 col-md-12 col-sm-12"
            data-aos="zoom-in-right"
          >
            <p>BEGINNEN SIE, IHRE KARRIERE AUFZUBAUEN</p>
            <h2>
              Professionelle Lebensläufe für effektive Vorstellungsgespräche
            </h2>
            <p>
              Eine großartige Bewerbung führt zu einem guten
              Vorstellungsgespräch. Ein beeindruckender Lebenslauf macht all das
              möglich. Beginnen Sie stark mit dem Einstellungsmanager, indem Sie
              ein positives professionelles Bild schaffen. Ein
              Vorstellungsgespräch kann viel einfacher sein, wenn sie einen
              positiven Eindruck von Ihrem Lebenslauf und Anschreiben haben.
            </p>
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Jetzt starten
              </Link>
            </button>
            &nbsp; &nbsp; &nbsp;
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                Lebenslauf Beispiele
              </Link>
            </button>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center"
            data-aos="zoom-in-left"
          >
            <img
              src={imge}
              alt="banner"
              height="50% !important"
              className="imge pt-lg-0 pt-md-5 pt-sm-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResumeEx;
