import React, { useState } from "react";
import Button from "../../../InputControl/Button";
import InputControl from "../../../InputControl/InputControl";

export default function ProjectBlock(props) {
  const [check, setCheck] = useState(true);
  const [title, setTitle] = useState(false);
  const [link, setLink] = useState();
  const [description, setDescription] = useState(false);

  const handleSubmit = () => {
    // console.log(title, link, description);
    if ([title, link, description].includes(false)) {
      setCheck(false);
      props.save(false);
    } else {
      setCheck(true);
      // props.setcomp(3)
      props.save(true);
      props.set([...props.data, { title, link, description }]);
    }
  };

  const closeModal = () => {
    setCheck(true);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-[100%] md:w-[48%] h-[80px]">
          <InputControl
            field="Title"
            errmsg="Ungültig"
            isComp
            placeholder="Projekttitel"
            set={setTitle}
          />
        </div>
        <div className="w-[100%] md:w-[48%] h-[80px]">
          <InputControl
            field="Link"
            errmsg="Ungültig"
            placeholder="Projektlink"
            set={setLink}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="w-full min-h-[80px]">
          <InputControl
            type="textarea"
            field="Description"
            isComp
            placeholder="Projektbeschreibung"
            set={setDescription}
          />
        </div>
      </div>
      <div className="w-full my-8">
        <div className="w-[100px]" onClick={handleSubmit}>
          <Button text="Speichern" />
        </div>
        <div className={`text-red-500 ${!check ? "block" : "hidden"}`}>
          {
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="p-8 bg-white rounded-md">
                <div className="mb-4 text-2xl font-bold">
                  Es gab einen Fehler
                </div>
                <div className="text-center text-md">
                  Überprüfen Sie, ob Sie gespeichert haben, oder ob Sie Ihre
                  Daten korrekt eingegeben haben. Stellen Sie sicher, dass die
                  Daten in der richtigen Reihenfolge sind.
                </div>
                <div className="flex justify-center mt-4" onClick={closeModal}>
                  <Button text="Schließen" />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}
